/**
 * 支付寶支付
 *
 * @author yubaoshan
 * @date 2020/8/26 00:17
 */
import { axios } from '@/utils/request'

/**
 * 獲取支付商戶號
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 */
export function getOutTradeNo (parameter) {
  return axios({
    url: '/aliPay/getOutTradeNo',
    method: 'get',
    params: parameter
  })
}

/**
 * pc支付
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 */
export function pcPay (parameter) {
  return axios({
    url: '/aliPay/pcPay',
    method: 'get',
    params: parameter
  })
}

/**
 * 掃碼支付
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 */
export function tradePreCreatePay (parameter) {
  return axios({
    url: '/aliPay/tradePreCreatePay',
    method: 'get',
    params: parameter,
    responseType: 'arraybuffer'
  })
}

/**
 * wap支付
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 */
export function wapPay (parameter) {
  return axios({
    url: '/aliPay/wapPay',
    method: 'get',
    params: parameter
  })
}

/**
 * 單筆轉賬
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 * */
export function transfer (parameter) {
  return axios({
    url: '/aliPay/transfer',
    method: 'post',
    data: parameter
  })
}

/**
 * 退款
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 * */
export function tradeRefund (parameter) {
  return axios({
    url: '/aliPay/tradeRefund',
    method: 'post',
    data: parameter
  })
}

/**
 * 創建訂單
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 * */
export function tradeCreate (parameter) {
  return axios({
    url: '/aliPay/tradeCreate',
    method: 'post',
    data: parameter
  })
}

/**
 * 撤銷訂單
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 * */
export function tradeCancel (parameter) {
  return axios({
    url: '/aliPay/tradeCancel',
    method: 'post',
    data: parameter
  })
}

/**
 * 關閉訂單
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 * */
export function tradeClose (parameter) {
  return axios({
    url: '/aliPay/tradeClose',
    method: 'post',
    data: parameter
  })
}

/**
 * 結算訂單
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 * */
export function tradeOrderSettle (parameter) {
  return axios({
    url: '/aliPay/tradeOrderSettle',
    method: 'post',
    data: parameter
  })
}

/**
 * 通知地址
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 * */
export function certNotify (parameter) {
  return axios({
    url: '/aliPay/certNotify',
    method: 'get',
    params: parameter
  })
}

/**
 * 返回地址
 *
 * @author yubaoshan
 * @date 2020/9/1 00:27
 * */
export function certReturn (parameter) {
  return axios({
    url: '/aliPay/certReturn',
    method: 'get',
    params: parameter
  })
}
