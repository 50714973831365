//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageHeader from '../../components/PageHeader/PageHeader'
import { billDownloadUrlQuery, tradeQuery } from '@/api/modular/pay/aliPayMgrManage'
import { getOutTradeNo } from '@/api/modular/pay/aliPayManage'
import moment from 'moment'

export default {
  components: { PageHeader },
  data () {
    return {
      payTyprItem: '',
      visible: false,
      confirmModalLoading: false,
      aliPayQrSrc: '',
      aliPayQrPrice: '',
      formShow: true,
      aliPayQrPriceShow: false,
      spinloading: false,
      billDownloadUrlQueryDate: '',
      indexloading: false,
      monthDay: '1',
      dlHref: '',
      dlHrefShow: false,
      outTradeNo: '',
      form: this.$form.createForm(this)
    }
  },
  methods: {
    moment,
    /**
     * 切換標簽
     */
    callback (val) {
    },
    /**
     * 點擊立即體驗
     */
    openPayInPrice (item) {
      this.visible = true
      this.payTyprItem = item
    },
    /**
     * 輸入完價格去執行支付方式
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          if (this.payTyprItem === 'pcAliPay') {
            this.pcAliPay(values)
          }
          if (this.payTyprItem === 'wapAliPay') {
            this.wapAliPay(values)
          }
          if (this.payTyprItem === 'tradePreCreateAliPay') {
            this.tradePreCreateAliPay(values)
          }
        }
      })
    },
    /**
     * 支付寶pc支付
     */
    pcAliPay (values) {
      getOutTradeNo().then((res) => {
        if (res.success) {
          this.outTradeNo = res.data
          window.open(process.env.VUE_APP_API_BASE_URL + '/aliPay/pcPay?amount=' + values.amount + '&outTradeNo=' + res.data, '_blank')
          this.handleCancel()
          this.showConfirm(this.outTradeNo, this.$message)
        } else {
          console.log('pc支付：' + JSON.stringify(res))
        }
      })
    },
    /**
     * 支付寶wap支付
     */
    wapAliPay (values) {
      getOutTradeNo().then((res) => {
        if (res.success) {
          this.outTradeNo = res.data
          window.open(process.env.VUE_APP_API_BASE_URL + '/aliPay/wapPay?amount=' + values.amount + '&outTradeNo=' + res.data, '_blank')
          this.handleCancel()
          this.showConfirm(this.outTradeNo, this.$message)
        } else {
          console.log('wap支付：' + JSON.stringify(res))
        }
      })
    },
    /**
     * 支付寶掃碼支付
     */
    tradePreCreateAliPay (values) {
      this.aliPayQrPrice = values.amount
      this.formShow = false
      this.aliPayQrPriceShow = true
      this.aliPayQrSrc = process.env.VUE_APP_API_BASE_URL + '/aliPay/tradePreCreatePay?amount=' + values.amount
      this.imgInit()
    },
    /**
     * 加載二維碼loading
     */
    imgInit () {
      this.spinloading = true
      var newImg = new Image()
      newImg.src = this.aliPayQrSrc
      newImg.onerror = () => { // 圖片加載錯誤時
        this.spinloading = false
        newImg.src = 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1489486509807&di=22213343ba71ad6436b561b5df999ff7&imgtype=0&src=http%3A%2F%2Fa0.att.hudong.com%2F77%2F31%2F20300542906611142174319458811.jpg'
      }
      newImg.onload = () => { // 圖片加載成功後
        this.spinloading = false
        this.aliPayQrSrc = newImg.src
        // this.getTradePreCreateAliPayStatus()
      }
    },
    /**
     * 定時器輪詢二維碼支付狀態
     */
    /* getTradePreCreateAliPayStatus(){
      let timer
      getPayStatus().then((res)=>{
        if(res.success){
          clearTimeout(timer) //清理定時任務
          this.handleCancel()
          this.$message.success('掃碼支付成功')
        }else{
          timer = setTimeout(() => {
            this.getTradePreCreateAliPayStatus()
          }, 2000)
        }
      }).catch(err=>{
        clearTimeout(timer)
        this.$message.error('掃碼支付遇到錯誤')
      })
    }, */
    /**
     * 選擇查詢方式
     */
    handleSeleChange (value) {
      this.billDownloadUrlQueryDate = ''
      this.monthDay = value
    },
    /**
     * 對賬單日期選擇
     */
    dateOnChange (date, dateString) {
      this.billDownloadUrlQueryDate = dateString
    },
    /**
     * 不可選的時間
     */
    disabledDate (current) {
      return moment().subtract(1, 'days') < current
    },
    /**
     * 查詢對賬單
     */
    searchBill () {
      if (this.billDownloadUrlQueryDate === '') {
        this.$message.error('請選擇對賬單時間')
      } else {
        this.indexloading = true
        billDownloadUrlQuery({ billDate: this.billDownloadUrlQueryDate }).then((res) => {
          this.indexloading = false
          if (res.success) {
            this.dlHref = res.data.bill_download_url
            this.dlHrefShow = true
          } else {
            this.dlHrefShow = false
            this.$message.error(res.message)
          }
        })
      }
    },
    /**
     * 彈框提示是否支付完成
     */
    showConfirm (outTradeNo, message) {
      this.$confirm({
        title: '請確認！',
        cancelText: '未支付',
        okText: '已支付完成',
        content: '您發起的訂單支付是否已支付完成？',
        onOk () {
          return new Promise((resolve, reject) => {
            const params = {
              outTradeNo: outTradeNo
            }
            tradeQuery(params).then((res) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
            })
          }).catch(() => console.log('Oops errors!'))
        },
        onCancel () {
          return new Promise((resolve, reject) => {
            const params = {
              outTradeNo: outTradeNo
            }
            tradeQuery(params).then((res) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
            })
          }).catch(() => console.log('Oops errors!'))
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.billDownloadUrlQueryDate = ''
      this.formShow = true
      this.aliPayQrPriceShow = false
      this.aliPayQrSrc = ''
      this.aliPayQrPrice = ''
      this.payTyprItem = ''
      this.visible = false
    }
  }
}
